import React, { ReactElement, useEffect } from "react"
import { navigate, PageProps } from "gatsby"
import SEO from "../../../components/SEO"
import { BasePageContext } from "../../../interfaces/PageContext"
import { useUser } from "../../../providers/UserProvider"
import Skeleton from "../../../components/Skeleton"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Button from "../../../components/design-system/Button"
import { useForm } from "react-hook-form"
import { ErrorTextWithIcon } from "../../../components/design-system/FormControl/FormControl"
import { useId } from "@reach/auto-id"
import useStageStatuses, { Status } from "../../../hooks/useStageStatuses"
import ButtonWrapper from "../../../components/ButtonWrapper"
import ConfidentialityAgreementText from "../../../components/ConfidentialityAgreementText"

const CompleteConfidentialityAgreement = (): ReactElement => {
  const { activity, updateActivity } = ConfidentialityAgreement.useUser()
  const { register, handleSubmit, formState, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      confidentialityDeclarationAgreed:
        activity?.confidentialityDeclarationAgreed,
    },
  })
  const checked = watch("confidentialityDeclarationAgreed")
  const feedbackTextId = "UCamConfidentialityFeedback" + useId()

  // When the checkbox is changed, update the activity document
  useEffect(() => {
    if (
      typeof checked === "boolean" &&
      activity?.confidentialityDeclarationAgreed !== checked
    ) {
      updateActivity("confidentialityDeclarationAgreed", checked)
    }
  }, [checked, activity, updateActivity])

  const hasErrors =
    !formState.isValid && (formState.isDirty || formState.isSubmitted)

  return (
    <>
      <ConfidentialityAgreementText firstSentence={true} />
      <form
        onSubmit={handleSubmit(() => {
          navigate("agree")
        })}
      >
        <FormControl error={hasErrors}>
          <FormControlLabel
            label="I agree to follow the confidentiality rules"
            className={hasErrors ? "Mui-error" : ""}
            control={
              <Checkbox
                className={hasErrors ? "Mui-error" : undefined}
                inputProps={{
                  "aria-describedby": hasErrors ? feedbackTextId : undefined,
                  "aria-invalid": hasErrors,
                }}
                {...register("confidentialityDeclarationAgreed", {
                  required: true,
                })}
                checked={checked}
              />
            }
          />
          {hasErrors && (
            <ErrorTextWithIcon
              id={feedbackTextId}
              data-test-id="conf-dec-validation-error"
            >
              {
                "Select 'I agree to follow the confidentiality rules' to continue"
              }
            </ErrorTextWithIcon>
          )}
        </FormControl>
        <ButtonWrapper>
          <Button type="submit">Continue</Button>
        </ButtonWrapper>
      </form>
    </>
  )
}

export const ConfidentialityAgreement = ({
  questions,
}: {
  questions: BasePageContext["questions"]
}): ReactElement => {
  const stageStatuses = ConfidentialityAgreement.useStageStatuses(questions)

  switch (stageStatuses.submit) {
    case Status.CannotStart:
      navigate("/application", { replace: true })
      break
    case Status.Complete:
      navigate("complete", { replace: true })
      break
    case undefined:
      // Loading state
      return (
        <>
          <ConfidentialityAgreementText firstSentence={true} />
          <FormControl>
            <Skeleton width="20rem" variant="rectangular" />
          </FormControl>
          <Skeleton width="10rem" variant="rectangular" />
        </>
      )
    case Status.InProgress:
    case Status.NotStarted:
      return <CompleteConfidentialityAgreement />
    default:
      throw new Error("app/invalid-submit-status")
  }

  // Navigating away
  return <></>
}

ConfidentialityAgreement.useStageStatuses = useStageStatuses
ConfidentialityAgreement.useUser = useUser

/**
 * The confidentiality agreement page renders a page to confirm that the applicant
 * has understood and agreed to the confidentiality declaration
 */
const ConfidentialityDeclarationPage = ({
  pageContext,
}: PageProps<never, BasePageContext>): ReactElement => {
  return (
    <>
      <SEO title="Confidentiality Declaration" />
      <ConfidentialityAgreement questions={pageContext.questions} />
    </>
  )
}

export default ConfidentialityDeclarationPage
