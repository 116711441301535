import React, { FC } from "react"
import {
  default as FormControl,
  FormControlProps as MuiFormControlProps,
} from "@mui/material/FormControl"
import { styled } from "@mui/material/styles"
import FormHelperText, {
  FormHelperTextProps,
} from "@mui/material/FormHelperText"
import MuiErrorOutlineIcon from "@mui/icons-material/WarningAmberOutlined"
import MuiCheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"
import MuiInfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

/** Feedback and error messages. */

export const FeedbackText = styled(FormHelperText)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  fontSize: "1rem",
  marginLeft: 0,
  marginRight: 0,
  display: "flex",
  "&.feedback-text--error": {
    color: theme.palette.error.dark,
  },
  "&.feedback-text--success": {
    color: theme.palette.success.dark,
  },
  "&.feedback-text--muted": {
    color: theme.palette.text.primary,
  },
  "&.feedback-text--info": {
    color: theme.palette.info.dark,
  },
  "& + &": {
    marginTop: 0,
  },
}))

const ErrorOutlineIcon = styled(MuiErrorOutlineIcon)(({ theme }) => ({
  marginRight: theme.spacing(0.75),
}))
const CheckCircleOutlinedIcon = styled(MuiCheckCircleOutlinedIcon)(
  ({ theme }) => ({
    marginRight: theme.spacing(0.75),
  })
)
const InfoOutlinedIcon = styled(MuiInfoOutlinedIcon)(({ theme }) => ({
  marginRight: theme.spacing(0.75),
}))

interface ErrorTextWithIconProps extends FormHelperTextProps {
  severity?: "error" | "success" | "muted" | "info"
}

export const ErrorTextWithIcon: FC<ErrorTextWithIconProps> = ({
  children,
  severity = "error",
  ...rest
}) => {
  const feedbackIcon =
    severity === "error" ? (
      <ErrorOutlineIcon />
    ) : severity === "success" ? (
      <CheckCircleOutlinedIcon />
    ) : severity === "muted" || severity === "info" ? (
      <InfoOutlinedIcon />
    ) : (
      <></>
    )

  return (
    <FeedbackText {...rest} className={`feedback-text--${severity}`}>
      {feedbackIcon}
      <span>{children}</span>
    </FeedbackText>
  )
}

/** Form control itself */

export type FormControlProps = MuiFormControlProps

export default FormControl
